<template>
  <a-modal
    width="1200px"
    v-model="isShow"
    title="订单详情"
    :mask-closable="false"
    :body-style="{ paddingLeft: 0 }"
  >
    <a-row type="flex" :gutter="24">
      <a-col flex="200px">
        <a-menu
          class="left-menus"
          mode="vertical"
          :default-selected-keys="[currentComponent]"
          @select="handleSelectMenu"
        >
          <a-menu-item
            v-for="item in tabs"
            :key="item.component"
          >
            <a-icon :type="item.icon" />
            {{ item.name }}
          </a-menu-item>
        </a-menu>
      </a-col>

      <a-col flex="990px" class="right-content">
        <keep-alive>
          <component
            :is="currentComponent"
            :id="id"
            :service-order-id="order.record_id"
            :order-type="orderType"
            ref="child"
          />
        </keep-alive>
      </a-col>
    </a-row>
    <template slot="footer">
      <div class="footer-btn">
        <a-button type="primary" @click="closeModal">关闭</a-button>
        <a-button type="primary" @click="updateModal">更新</a-button>
        <a-button
          type="primary"
          @click="makeObituaryModal"
          v-if="orderType === 'service' && !['unpaid', 'completed', 'cancelled', 'terminated', 'not_submit'].includes(orderStatus)"
        >发送讣告</a-button>
        <a-button type="primary" @click="showToDressRoomModal" v-if="['received'].includes(orderStatus)">送至穿衣间</a-button>
        <a-button type="primary" @click="showProcessNotificationModal" v-if="['received'].includes(orderStatus)">发送相关手续告知书</a-button>
        <a-button type="primary" @click="selectPackageModal" v-if="['not_selected_package'].includes(orderStatus)">选择套餐</a-button>
        <a-button type="primary" @click="chooseHallModal" v-if="['not_selected_hall'].includes(orderStatus)">选择厅房</a-button>
        <a-button
          type="primary"
          @click="changeHallModal"
          v-if="['unconfirmed_other'].includes(orderStatus) &&
            ['to_dress_room','to_dress','to_hall'].includes(order.body_dress_task_status_slug)"
        >修改厅房</a-button>
        <a-button
          type="primary"
          @click="showDetermineOtherItemsModal"
          v-if="['unconfirmed_other'].includes(orderStatus) && !order.is_confirm_other"
        >确定其他项目
        </a-button>
        <a-button
          type="primary"
          @click="showConfirmFuneralHandoverModal"
          v-if="['unconfirmed_other'].includes(orderStatus)"
        >穿衣交接确认
        </a-button>
        <a-button type="primary" @click="showAddItemsModal" v-if="canAddOtherItems(orderType,orderStatus)">新增项目
        </a-button>
        <a-button type="primary" @click="showUploadDeathImgModal" v-if="order.is_upload_death_img_or_video">上传遗像
        </a-button>
        <a-button type="primary" @click="showUploadDeathVideoModal" v-if="order.is_upload_death_img_or_video">上传视频
        </a-button>
        <a-button type="primary" @click="showModifyHeadUserModal" v-if="hasModifyUser(orderType,orderStatus)">修改负责人
        </a-button>
        <a-button
            type="primary"
            @click="terminationllOrder"
            v-if="orderType === 'service' &&
              ['not_received', 'received','not_selected_package','not_selected_hall','unconfirmed_other', 'not_wear_armband_notice', 'not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work','unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal'].includes(orderStatus)"
        >终止订单
        </a-button>
        <a-space v-if="!isTalkRole">
          <a-button
            type="danger"
            @click="showCancelServiceOrderModal"
            v-if="['unconfirmed', 'stock', 'un_deliver', 'transport','not_submit'].includes(orderStatus)"
          >取消订单
          </a-button>
          <a-button
            type="primary"
            @click="subscribeOrderDeliverModal"
            v-if="orderType !== 'service' && orderStatus == 'un_deliver'"
          >发货
          </a-button>
          <a-button
            type="primary"
            @click="showLocationModal"
            v-if="orderType !== 'add_item' && ['not_received', 'transport'].includes(orderStatus)"
          >实时位置
          </a-button>
          <a-button type="primary" @click="showConfirmOrderModal" v-if="['unconfirmed'].includes(orderStatus)">确认接单
          </a-button>
          <a-button
            type="primary"
            @click="sendWearArmbandNoticeModal"
            v-if="['not_wear_armband_notice'].includes(orderStatus)"
          >发送戴号提醒
          </a-button>
          <a-button
              v-if="order.is_confirm_other &&
              order.record_type_slug === 'service' &&
              !['not_hold_funeral_ceremony','cancelled', 'completed', 'terminated'].includes(orderStatus)"
              type="primary"
              @click="showUpdateOtherItemsModal"
          >
            修改项目
          </a-button>
          <a-button
            type="primary"
            @click="wearArmband"
            v-if="['not_hold_wear_armband_ceremony'].includes(orderStatus)"
          >进行戴号仪式
          </a-button>
          <a-button
            type="primary"
            @click="prepareProvideFoodCeremonyModal"
            v-if="['not_prepare_provide_food_ceremony'].includes(orderStatus)"
          >准备贡菜供饭仪式
          </a-button>
          <a-button
            type="primary"
            @click="showSendFirstNightWatchingNoticeModal"
            v-if="['not_first_night_watching'].includes(orderStatus)"
          >发送第一守灵提醒
          </a-button>
          <a-button
            type="primary"
            @click="confirmDirectorModal"
            v-if="['unconfirmed_director_work'].includes(orderStatus)"
          >确认理事工作
          </a-button>
          <a-button
            type="primary"
            @click="showConfirmedLifeInfoModal"
            v-if="['unconfirmed_life_info'].includes(orderStatus)"
          >确认逝者生平事迹
          </a-button>
          <a-button
            type="primary"
            @click="showSendLibationBoxModal"
            v-if="['not_hold_libation_box_ceremony'].includes(orderStatus)"
          >发送奠盒仪式提醒
          </a-button>
          <a-button
            type="primary"
            @click="showSendSecondNightWatchingNoticeModal"
            v-if="['not_second_night_watching'].includes(orderStatus)"
          >发送第二守灵提醒
          </a-button>
          <a-button
            type="primary"
            @click="showUploadEmceeDraftModal"
            v-if="!order.is_finish_upload_emcee_draft && order.is_need_emcee_draft &&
              ('not_settlement' === orderStatus && order.stopped === false || ['not_hold_libation_box_ceremony','not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal'].includes(orderStatus))"
          >上传司仪稿
          </a-button>
          <a-button
            type="primary"
            @click="showSendThankfulCeremonyNoticeModal"
            v-if="['not_hold_be_thankful_ceremony'].includes(orderStatus)"
          >发送感恩仪式提醒
          </a-button>
          <a-button type="primary" @click="showSendMealNoticeModal" v-if="['not_meal'].includes(orderStatus)">发送就餐通知
          </a-button>
          <a-button
            type="primary"
            @click="billingDetailsModal"
            v-if="order.record_type_slug === 'service' && order.is_show_settle && ['not_settlement', 'completed', 'terminated'].includes(orderStatus)"
          >结算详情
          </a-button>
          <a-button
            type="primary"
            @click="showSendFuneralCeremonyModal"
            v-if="['not_hold_funeral_ceremony'].includes(orderStatus)"
          >发送告别出殡仪式提醒
          </a-button>
          <!--      <a-button type="primary" @click="finishHoldFuneralCeremonyModal" v-if="['not_hold_funeral_ceremony'].includes(orderStatus)">完成出殡</a-button>-->
        </a-space>
        <a-button
          type="primary"
          @click="showModifyApplyModal"
          v-if="orderType === 'service' && ['unconfirmed','not_received', 'received','not_selected_package','not_selected_hall','unconfirmed_other', 'not_wear_armband_notice', 'not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work','unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal', 'not_settlement', 'not_hold_funeral_ceremony'].includes(orderStatus)"
        >
          修改丧属
        </a-button>
        <a-button
          type="primary"
          @click="showUserNoticeModal"
          v-if="orderType === 'service' &&
            this.isTalkRole &&
            ['not_wear_armband_notice', 'not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work', 'unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal', 'not_settlement', 'not_hold_funeral_ceremony'].includes(orderStatus)"
        >
          通知负责人
        </a-button>
        <a-button
          type="primary"
          @click="printOrder"
          :loading="loadingPDF"
          v-if="orderType === 'service' && ['not_hold_funeral_ceremony', 'completed', 'terminated'].includes(orderStatus)"
        >
          打印
        </a-button>
        <a-button
          type="primary"
          @click="showPrintQrModal"
          v-if="orderType === 'service' && ['unconfirmed', 'not_received', 'received','not_selected_package','not_selected_hall','unconfirmed_other', 'not_wear_armband_notice','not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work','unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal', 'not_settlement', 'not_hold_funeral_ceremony'].includes(orderStatus)"
        >打印二维码
        </a-button>
        <a-button
          type="primary"
          @click="printHandoverPdf"
          :loading="loadingHandoverPDF"
          v-if="orderType === 'service' && !['unpaid','unconfirmed','not_submit', 'cancelled'].includes(orderStatus)"
        >
          打印穿衣交接单
        </a-button>
        <a-button
          type="primary"
          @click="printRelevantProceduresPDF"
          :loading="loadingRelevantProceduresPDF"
          v-if="orderType === 'service' && !['unpaid', 'unconfirmed', 'not_received', 'not_submit','completed','cancelled','terminated'].includes(orderStatus)"
        >
          打印相关手续告知书
        </a-button>
        <a-button
          type="primary"
          @click="showUpdateTransportationModal"
          v-if="['unpaid', 'unconfirmed', 'not_received'].includes(orderStatus) && orderType=== 'service'"
        >
          修改接运点
        </a-button>
        <a-button
          type="primary"
          @click="showConfirmAuditoriumServiceModal"
          v-if="!['unpaid','unconfirmed','not_received','received',
                  'not_selected_package','not_selected_hall','terminated',
                  'cancelled','not_submit', 'completed'].includes(orderStatus) && orderType === 'service'"
        >
          礼厅服务交接
        </a-button>
        <a-button
          type="primary"
          @click="printConfirmAuditoriumService"
          v-if="!['unpaid','unconfirmed','not_received','received',
                  'not_selected_package','not_selected_hall',
                  'cancelled','not_submit'].includes(orderStatus) && orderType === 'service'"
        >
          打印礼厅服务交接单
        </a-button>
      </div>
    </template>
    <!-- 确认接单模态框 -->
    <show-confirm-order
      v-if="isShowConfirmOrderModal"
      :visible.sync="isShowConfirmOrderModal"
      :id="id"
      :service-order-id="serviceOrderId"
      :order-type="orderType"
      @completed="fetchOrder"
    />
    <!-- 确认接单模态框 -->
    <show-confirm-subscribe
      v-if="isShowConfirmSubscribeModal"
      :visible.sync="isShowConfirmSubscribeModal"
      :service-order-id="serviceOrderId"
      :order-type="orderType"
      @completed="fetchOrder"
    />
    <!-- 发货 -->
    <show-subscribe-order-deliver
      v-if="isShowSubscribeOrderDeliverModal"
      :visible.sync="isShowSubscribeOrderDeliverModal"
      :service-order-id="serviceOrderId"
      :order-type="orderType"
      @completed="fetchOrder"
    />
    <!--打印二维码 -->
    <show-print-qr
      v-if="isShowPrintQrModal"
      :visible.sync="isShowPrintQrModal"
      :id="id"
      :order-record="order"
      @completed="fetchOrder"
    />
    <!--修改丧属 -->
    <show-modify-master
      v-if="isShowModifyMasterModal"
      :visible.sync="isShowModifyMasterModal"
      :id="id"
      :name="name"
      :phone="phone"
      @completed="fetchOrder"
    />
    <!--修改丧属申请 -->
    <show-modify-master-apply
      v-if="isShowModifyMasterApplyModal"
      :visible.sync="isShowModifyMasterApplyModal"
      :id="id"
      :name="name"
      :phone="phone"
      @completed="fetchOrder"
    />
    <!--终止订单 -->
    <show-termination-order
      v-if="isShowTerminationOrderModal"
      :visible.sync="isShowTerminationOrderModal"
      :id="id"
      :service-order-id="serviceOrderId"
      @completed="fetchOrder"
    />
    <!--修改负责人 -->
    <show-modify-head-user
      v-if="isShowModifyHeadUserModal"
      :visible.sync="isShowModifyHeadUserModal"
      :id="id"
      :user-id="userId"
      @completed="fetchOrder"
    />
    <!--选择套餐 -->
    <show-select-package
      v-if="isShowSelectPackageModalModal"
      :visible.sync="isShowSelectPackageModalModal"
      :id="id"
      :service-order-package-id="serviceOrderPackageId"
      :service-order-id="serviceOrderId"
      :funeral-home-id="funeralHomeId"
      @completed="fetchOrder"
    />
    <!--修改负责人申请 -->
    <show-modify-head-user-apply
      v-if="isShowModifyHeadUserApplyModal"
      :visible.sync="isShowModifyHeadUserApplyModal"
      :id="id"
      :name="name"
      :phone="phone"
      @completed="fetchOrder"
    />
    <!--取消订单 -->
    <show-cancel-service-order
      v-if="isShowCancelServiceOrderModal"
      :visible.sync="isShowCancelServiceOrderModal"
      :id="id"
      :order-type="orderType"
      :service-order-id="serviceOrderId"
      @completed="fetchOrder"
    />
    <!--确认理事 -->
    <show-confirm-director
      v-if="isShowConfirmDirectorModal"
      :visible.sync="isShowConfirmDirectorModal"
      :service-order-id="serviceOrderId"
      :id="id"
      @completed="fetchOrder"
    />
    <!--送到穿衣间 -->
    <show-to-dress-room
      v-if="isShowToDressRoomModal"
      :visible.sync="isShowToDressRoomModal"
      :id="id"
      :service-order-id="serviceOrderId"
      :order-type="orderType"
      @completed="fetchOrder"
    />
    <!--发送办理相关手续告知书 -->
    <show-process-notification
      v-if="isShowProcessNotificationModal"
      :visible.sync="isShowProcessNotificationModal"
      :id="id"
      @completed="fetchOrder"
    />
    <!--选择厅房 -->
    <show-choose-hall
      v-if="isShowchooseHallModal"
      :visible.sync="isShowchooseHallModal"
      :id="id"
      :hall-id="hallId"
      :stop-coffins-times="stopCoffinsTimes"
      :unit="unit"
      :is-change-hall="isChangeHall"
      :is-disinfect-old="isDisinfect"
      :is-buddhist-venue-old="isBuddhistVenue"
      @completed="fetchOrder"
    />
    <!--发送戴号提醒 -->
    <show-sendWear-armband-notice
      v-if="isShowSendWearArmbandNoticeModal"
      :visible.sync="isShowSendWearArmbandNoticeModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :funeral-home-id="funeralHomeId"
      @completed="fetchOrder"
    />
    <!--制作讣告 -->
    <show-send-obituary
      v-if="isShowSendObituaryModal"
      :visible.sync="isShowSendObituaryModal"
      :service-order-id="serviceOrderId"
      :id="id"
      @completed="fetchOrder"
    />
    <!--准备供菜供饭任务 -->
    <show-prepare-provide-food-ceremony
      v-if="isShowPrepareProvideFoodCeremonyModal"
      :visible.sync="isShowPrepareProvideFoodCeremonyModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :funeral-home-id="funeralHomeId"
      @completed="fetchOrder"
    />
    <!--发送第一夜守灵提醒 -->
    <show-send-first-night-watching-notice
      v-if="isShowSendFirstNightWatchingNoticeModal"
      :visible.sync="isShowSendFirstNightWatchingNoticeModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :funeral-home-id="funeralHomeId"
      @completed="fetchOrder"
    />
    <!--发送第二夜守灵提醒 -->
    <show-send-Second-night-watching-notice
      v-if="isShowSendSecondNightWatchingNoticeModal"
      :visible.sync="isShowSendSecondNightWatchingNoticeModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :funeral-home-id="funeralHomeId"
      @completed="fetchOrder"
    />
    <!--确认逝者生平事迹 -->
    <show-confirmed-life-info
      v-if="isShowConfirmedLifeInfoModal"
      :visible.sync="isShowConfirmedLifeInfoModal"
      :service-order-id="serviceOrderId"
      :id="id"
      @completed="fetchOrder"
    />
    <!--上传司仪稿 -->
    <show-upload-emcee-draft
      v-if="isShowuploadEmceeDraftModal"
      :visible.sync="isShowuploadEmceeDraftModal"
      :service-order-id="serviceOrderId"
      @completed="fetchOrder"
    />
    <!--上传遗像 -->
    <show-upload-death-image
      v-if="isShowUploadDeathImgModal"
      :visible.sync="isShowUploadDeathImgModal"
      :service-order-id="serviceOrderId"
      @completed="fetchOrder"
    />
    <!--上传视频 -->
    <show-upload-death-video
      v-if="isShowUploadDeathVideoModal"
      :visible.sync="isShowUploadDeathVideoModal"
      :service-order-id="serviceOrderId"
      @completed="fetchOrder"
    />
    <!--发送奠盒仪式提醒 -->
    <show-send-libation-box
      v-if="isShowSendLibationBoxModal"
      :visible.sync="isShowSendLibationBoxModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :funeral-home-id="funeralHomeId"
      @completed="fetchOrder"
    />
    <!--发送感恩仪式提醒 -->
    <show-send-thankful-ceremony
      v-if="isShowSendThankfulCeremonyNoticeModal"
      :visible.sync="isShowSendThankfulCeremonyNoticeModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :funeral-home-id="funeralHomeId"
      :begin-time="thankfulBeginTime"
      :end-time="thankfulEndTime"
      @completed="fetchOrder"
    />
    <!--发送就餐提醒 -->
    <show-send-meal-notice
      v-if="isShowSendMealNoticeModal"
      :visible.sync="isShowSendMealNoticeModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :begin-time="mealBeginTime"
      :end-time="mealEndTime"
      @completed="fetchOrder"
    />

    <send-funeral-ceremony-notice
      v-if="isShowSendFuneralCeremonyModal"
      :visible.sync="isShowSendFuneralCeremonyModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :funeral-home-id="funeralHomeId"
      :begin-time="funeralBeginTime"
      :end-time="funeralEndTime"
      @completed="fetchData"
    />
    <!--完成出殡 -->
    <show-finish-funeral-ceremony
      v-if="isShowFinishHoldFuneralCeremonyModal"
      :visible.sync="isShowFinishHoldFuneralCeremonyModal"
      :service-order-id="serviceOrderId"
      @completed="fetchOrder"
    />
    <!-- 通知负责人 -->
    <send-user-notice
      v-if="isShowUserNoticeModal"
      :visible.sync="isShowUserNoticeModal"
      :service-order-id="serviceOrderId"
      :hall-name="hallName"
      :death-name="deathName"
      :order-no="orderNo"
      :order-user="orderUserName"
      @completed="fetchOrder"
    />
    <item-order-batch-record
      v-if="isShowDetermineOtherItemsModal"
      :visible.sync="isShowDetermineOtherItemsModal"
      :service-order-id="serviceOrderId"
      :id="id"
      @completed="fetchOrder"
    />
    <!--殡仪交接确认 -->
    <confirm-funeral-handover
      v-if="isShowConfirmFuneralHandoverModal"
      :visible.sync="isShowConfirmFuneralHandoverModal"
      :id="id"
      @completed="fetchOrder"
    />
    <modify-other-item
      v-if="isShowUpdateOtherItemsModal"
      :visible.sync="isShowUpdateOtherItemsModal"
      :service-order-id="serviceOrderId"
      :id="id"
      @completed="fetchOrder"
    />
    <!--操作历史 -->
    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="serviceOrderId"
      :reference-type="historyOrderType"
      :visible.sync="isShowOperationHistoryModal"
    />
    <!--    实时位置-->
    <show-real-location
      v-if="isShowRealLocationModal"
      :visible.sync="isShowRealLocationModal"
      :task-id="taskId"
    />

    <add-items
      v-if="isShowAddOtherItemsModal"
      :service-order-id="serviceOrderId"
      :visible.sync="isShowAddOtherItemsModal"
    />
    <update-transportation
      v-if="isShowUpdateTransportationModal"
      :order-id="id"
      :visible.sync="isShowUpdateTransportationModal"
      @completed="fetchOrder"
    />
    <confirm-auditorium-service
      v-if="isShowConfirmAuditoriumServiceModal"
      :id="id"
      :visible.sync="isShowConfirmAuditoriumServiceModal"
      @completed="fetchOrder"
    />
  </a-modal>
</template>

<script>
import {
  validUpdateOrderMaster,
  validUpdateOrderHeadUser,
  findTenantOrder,
  createSettlementPDF,
  printHandoverPdf,
  printRelevantProceduresPDF,
  wearArmband, createAuditoriumServicePdf
} from '@/api/order'
import printJS from 'print-js'
import { canAddOtherItems } from '@/utils/service_order'

export default {
  name: 'ShowDevice',
  components: {
    BasicInformation: () => import('@/views/orders/tenant/BasicInformation'),
    SubscribeBasicInformation: () => import('@/views/orders/tenant/SubscribeBasicInformation'),
    DeceasedInfo: () => import('@/views/orders/tenant/deaths/DeceasedInfo'),
    PackageInfo: () => import('@/views/orders/tenant/PackageInfo'),
    OtherProjects: () => import('@/views/orders/tenant/other_projects/index'),
    TaskList: () => import('@/views/orders/tenant/TaskList'),
    SubscribeOrderProduct: () => import('@/views/orders/tenant/SubscribeOrderProduct'),
    OperationHistory: () => import('@/views/orders/tenant/OperationHistory'),
    FamilyNotification: () => import('@/views/orders/tenant/family_notification/index'),
    UploadFile: () => import('@/views/orders/tenant/upload_file/index'),
    ShowConfirmOrder: () => import('@/views/orders/tenant/operation_modal/ConfirmOrder'),
    ShowConfirmSubscribe: () => import('@/views/orders/tenant/operation_modal/ConfirmSubscribe'),
    ShowPrintQr: () => import('@/views/orders/tenant/operation_modal/PrintQr'),
    ShowModifyMaster: () => import('@/views/orders/tenant/operation_modal/ModifyMaster'),
    ShowModifyMasterApply: () => import('@/views/orders/tenant/operation_modal/ModifyMasterApply'),
    ShowCancelServiceOrder: () => import('@/views/orders/tenant/operation_modal/CancelServiceOrder'),
    ShowToDressRoom: () => import('@/views/orders/tenant/operation_modal/ToDressRoom'),
    ShowProcessNotification: () => import('@/views/orders/tenant/operation_modal/ProcessNotification'),
    ShowChooseHall: () => import('@/views/orders/tenant/operation_modal/ChooseHall'),
    ShowSendWearArmbandNotice: () => import('@/views/orders/tenant/operation_modal/SendWearArmbandNotice'),
    ShowSendObituary: () => import('@/views/orders/tenant/operation_modal/SendObituary'),
    ShowPrepareProvideFoodCeremony: () => import('@/views/orders/tenant/operation_modal/PrepareProvideFoodCeremony'),
    ShowSendFirstNightWatchingNotice: () => import('@/views/orders/tenant/operation_modal/SendFirstNightWatchingNotice'),
    ShowSendSecondNightWatchingNotice: () => import('@/views/orders/tenant/operation_modal/SendSecondNightWatchingNotice'),
    ShowConfirmedLifeInfo: () => import('@/views/orders/tenant/operation_modal/ConfirmedLifeInfo'),
    ShowUploadEmceeDraft: () => import('@/views/orders/tenant/operation_modal/UploadEmceeDraft'),
    ShowUploadDeathImage: () => import('@/views/orders/tenant/operation_modal/UploadDeathImage'),
    ShowUploadDeathVideo: () => import('@/views/orders/tenant/operation_modal/UploadDeathVideo'),
    ShowSendLibationBox: () => import('@/views/orders/tenant/operation_modal/SendLibationBoxCeremonyNotice'),
    ShowSendMealNotice: () => import('@/views/orders/tenant/operation_modal/SendMealNotice'),
    ShowFinishFuneralCeremony: () => import('@/views/orders/tenant/operation_modal/FinishHoldFuneralCeremony'),
    ShowSendThankfulCeremony: () => import('@/views/orders/tenant/operation_modal/SendThankfulCeremonyNotice'),
    ShowModifyHeadUser: () => import('@/views/orders/tenant/operation_modal/ModifyHeadUser'),
    ShowModifyHeadUserApply: () => import('@/views/orders/tenant/operation_modal/ModifyHeadUserApply'),
    ShowSubscribeOrderDeliver: () => import('@/views/orders/tenant/operation_modal/SubscribeOrderDeliver'),
    ShowConfirmDirector: () => import('@/views/orders/tenant/operation_modal/ConfirmDirector'),
    ShowSelectPackage: () => import('@/views/orders/tenant/operation_modal/SelectPackage'),
    // ShowDetermineOtherItems: () => import('@/views/orders/tenant/operation_modal/DetermineOtherItems'),
    ShowUpdateOtherItems: () => import('@/views/orders/tenant/operation_modal/UpdateOtherItems'),
    ShowTerminationOrder: () => import('@/views/orders/tenant/operation_modal/TerminationOrder'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    ModifyOtherItem: () => import('@/views/orders/tenant/operation_modal/ModifyOtherItem'),
    ItemOrderBatchRecord: () => import('@/views/orders/tenant/operation_modal/ItemOrderBatchRecord'),
    ShowRealLocation: () => import('@/views/location/ShowRealLocation'),
    SendFuneralCeremonyNotice: () => import('@/views/orders/tenant/operation_modal/SendFuneralCeremonyNotice'),
    AddItems: () => import('@/views/orders/tenant/operation_modal/AddItems'),
    SendUserNotice: () => import('@/views/orders/tenant/operation_modal/SendUserNotice'),
    ConfirmFuneralHandover: () => import('@/views/orders/tenant/operation_modal/ConfirmFuneralHandover'),
    UpdateTransportation: () => import('@/views/orders/tenant/operation_modal/UpdateTransportation.vue'),
    ConfirmAuditoriumService: () => import('@/views/orders/tenant/operation_modal/ConfirmAuditoriumService.vue')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    orderType: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      order: {},
      device: {},
      attribute: 'basic_information',
      isShowConfirmOrderModal: false,
      isShowConfirmSubscribeModal: false,
      isShowPrintQrModal: false,
      isShowModifyMasterModal: false,
      isShowModifyMasterApplyModal: false,
      isShowCancelServiceOrderModal: false,
      isShowToDressRoomModal: false,
      isShowProcessNotificationModal: false,
      isShowchooseHallModal: false,
      isShowSendWearArmbandNoticeModal: false,
      isShowSendObituaryModal: false,
      isShowPrepareProvideFoodCeremonyModal: false,
      isShowSendFirstNightWatchingNoticeModal: false,
      isShowConfirmedLifeInfoModal: false,
      isShowuploadEmceeDraftModal: false,
      isShowUploadDeathImgModal: false,
      isShowUploadDeathVideoModal: false,
      isShowSendLibationBoxModal: false,
      isShowSendSecondNightWatchingNoticeModal: false,
      isShowSendMealNoticeModal: false,
      isShowFinishHoldFuneralCeremonyModal: false,
      isShowSendThankfulCeremonyNoticeModal: false,
      isShowModifyHeadUserApplyModal: false,
      isShowModifyHeadUserModal: false,
      isShowSubscribeOrderDeliverModal: false,
      isShowConfirmDirectorModal: false,
      isShowSelectPackageModalModal: false,
      isShowDetermineOtherItemsModal: false,
      isShowAddOtherItemsModal: false,
      isShowUpdateOtherItemsModal: false,
      isShowTerminationOrderModal: false,
      isShowOperationHistoryModal: false,
      isShowRealLocationModal: false,
      isShowSendFuneralCeremonyModal: false,
      isShowUserNoticeModal: false,
      isShowConfirmFuneralHandoverModal: false,
      loadingPDF: false,
      loadingHandoverPDF: false,
      loadingRelevantProceduresPDF: false,
      isShowUpdateTransportationModal: false,
      isShowConfirmAuditoriumServiceModal: false,
      historyOrderType: '',
      name: '',
      phone: '',
      hallName: '',
      deathName: '',
      orderNo: '',
      orderUserName: '',
      userId: 0,
      hallId: 0,
      orderId: this.id,
      orderStatus: '',
      serviceOrderId: 0,
      serviceOrderPackageId: 0,
      funeralHomeId: 0,
      currentComponent: 'BasicInformation',
      tabs: [],
      serviceOrderTabs: [
        {
          component: 'BasicInformation',
          name: '基本信息',
          icon: 'profile'
        },
        {
          component: 'DeceasedInfo',
          name: '逝者信息',
          icon: 'laptop'
        },
        {
          component: 'PackageInfo',
          name: '套餐信息',
          icon: 'inbox'
        },
        {
          component: 'OtherProjects',
          name: '其他项目',
          icon: 'inbox'
        },
        {
          component: 'TaskList',
          name: '任务列表',
          icon: 'inbox'
        },
        {
          component: 'OperationHistory',
          name: '操作历史',
          icon: 'history'
        },
        {
          component: 'FamilyNotification',
          name: '家属告知书',
          icon: 'inbox'
        },
        {
          component: 'UploadFile',
          name: '上传资料',
          icon: 'upload'
        }
      ],
      subscribeOrderTabs: [
        {
          component: 'SubscribeBasicInformation',
          name: '基本信息',
          icon: 'profile'
        },
        {
          component: 'SubscribeOrderProduct',
          name: '产品信息',
          icon: 'inbox'
        },
        {
          component: 'TaskList',
          name: '任务列表',
          icon: 'inbox'
        },
        {
          component: 'OperationHistory',
          name: '操作历史',
          icon: 'history'
        }
      ],
      taskId: 0,
      isChangeHall: false,
      isDisinfect: false,
      isBuddhistVenue: false,
      thankfulBeginTime: '',
      thankfulEndTime: '',
      funeralBeginTime: '',
      funeralEndTime: '',
      mealBeginTime: '',
      mealEndTime: ''
    }
  },
  created() {
    this.fetchOrder()
    this.fetchTabs()
    this.getOrderStatus()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    isTalkRole() {
      return this.$store.getters.userRole === 'tenant_talk'
    }
  },
  methods: {
    canAddOtherItems(orderType, orderStatus) {
      return this.order.is_confirm_other && canAddOtherItems(orderType, orderStatus)
    },
    hasModifyUser(orderType, orderStatus) {
      if (orderType === 'service') {
        return !['terminated', 'cancelled', 'completed'].includes(orderStatus)
      } else if (orderType === 'add_item') {
        return !['canceled', 'completed'].includes(orderStatus)
      } else if (orderType === 'subscribe') {
        return !['cancelled', 'completed'].includes(orderStatus)
      }
      return false
    },
    getOrderStatus() {
      this.orderStatus = this.order.order_status_slug
    },
    fetchOrder() {
      findTenantOrder(this.id).then(res => {
        if (res.code === 0) {
          this.order = res.data
          this.getOrderStatus()
        }
      })
    },
    fetchData() {
      this.$refs.child.fetchData()
    },
    printHandoverPdf() {
      this.$info({
        title: '正在生成穿衣交接PDF文件，请耐心等候',
        content: ''
      })
      if (this.loadingHandoverPDF) {
        return
      }

      this.loadingHandoverPDF = true
      printHandoverPdf(this.id).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
      this.loadingHandoverPDF = false
    },
    printRelevantProceduresPDF() {
      this.$info({
        title: '正在生成相关手续告知书PDF文件，请耐心等候',
        content: ''
      })
      if (this.loadingRelevantProceduresPDF) {
        return
      }

      this.loadingRelevantProceduresPDF = true
      printRelevantProceduresPDF(this.id).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
      this.loadingRelevantProceduresPDF = false
    },
    printOrder() {
      this.$info({
        title: '正在生成结算清单PDF文件，请耐心等候',
        content: ''
      })
      if (this.loadingPDF) {
        return
      }

      this.loadingPDF = true
      createSettlementPDF(this.id).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
      this.loadingPDF = false
    },
    showLocationModal() {
      this.isShowRealLocationModal = true
      this.taskId = this.order.task_id
    },
    showDetermineOtherItemsModal() {
      this.isShowDetermineOtherItemsModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showConfirmFuneralHandoverModal() {
      this.isShowConfirmFuneralHandoverModal = true
    },
    showAddItemsModal() {
      this.isShowAddOtherItemsModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showUpdateTransportationModal() {
      this.isShowUpdateTransportationModal = true
    },
    showHistoryOrder() {
      this.serviceOrderId = this.order.service_order_id
      if (this.orderType === 'service') {
        this.historyOrderType = 'ServiceOrder'
      } else if (this.orderType === 'subscribe') {
        this.historyOrderType = 'SubscribeOrder'
      } else {
        this.historyOrderType = 'AdditionalItemOrder'
      }
      this.isShowOperationHistoryModal = true
    },
    showUpdateOtherItemsModal() {
      this.isShowUpdateOtherItemsModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    selectPackageModal() {
      this.isShowSelectPackageModalModal = true
      this.serviceOrderId = this.order.service_order_id
      this.serviceOrderPackageId = this.order.service_order_package_id
      this.funeralHomeId = this.order.funeral_home_id
    },
    confirmDirectorModal() {
      this.isShowConfirmDirectorModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    subscribeOrderDeliverModal() {
      this.isShowSubscribeOrderDeliverModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showSendThankfulCeremonyNoticeModal() {
      this.isShowSendThankfulCeremonyNoticeModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.funeralHomeId = this.order.funeral_home_id
      this.thankfulBeginTime = this.order.thankful_begin_time
      this.thankfulEndTime = this.order.thankful_end_time
    },
    finishHoldFuneralCeremonyModal() {
      this.isShowFinishHoldFuneralCeremonyModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showSendMealNoticeModal() {
      this.isShowSendMealNoticeModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.mealBeginTime = this.order.meal_begin_time
      this.mealEndTime = this.order.meal_end_time
    },
    showSendLibationBoxModal() {
      this.isShowSendLibationBoxModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.funeralHomeId = this.order.funeral_home_id
    },
    showSendFuneralCeremonyModal() {
      this.isShowSendFuneralCeremonyModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.funeralHomeId = this.order.funeral_home_id
      this.funeralEndTime = this.order.funeral_end_time
      this.funeralBeginTime = this.order.funeral_begin_time
    },
    showUploadEmceeDraftModal() {
      this.isShowuploadEmceeDraftModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showUploadDeathImgModal() {
      this.isShowUploadDeathImgModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showUploadDeathVideoModal() {
      this.isShowUploadDeathVideoModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showConfirmedLifeInfoModal() {
      this.isShowConfirmedLifeInfoModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showSendFirstNightWatchingNoticeModal() {
      this.isShowSendFirstNightWatchingNoticeModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.funeralHomeId = this.order.funeral_home_id
    },
    showSendSecondNightWatchingNoticeModal() {
      this.isShowSendSecondNightWatchingNoticeModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.funeralHomeId = this.order.funeral_home_id
    },
    prepareProvideFoodCeremonyModal() {
      this.isShowPrepareProvideFoodCeremonyModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.funeralHomeId = this.order.funeral_home_id
    },
    makeObituaryModal() {
      this.isShowSendObituaryModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    chooseHallModal() {
      this.isChangeHall = false
      this.isShowchooseHallModal = true
      this.hallId = this.order.hall_id
      this.unit = this.order.unit
      this.stopCoffinsTimes = this.order.stop_coffins_times
      this.isDisinfect = true
      this.isBuddhistVenue = true
    },
    changeHallModal() {
      this.isChangeHall = true
      this.isShowchooseHallModal = true
      this.hallId = this.order.hall_id
      this.unit = this.order.unit
      this.stopCoffinsTimes = this.order.stop_coffins_times
      this.isDisinfect = this.order.is_disinfect
      this.isBuddhistVenue = this.order.is_buddhist_venue
    },
    terminationllOrder() {
      this.isShowTerminationOrderModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    billingDetailsModal() {
      this.$router.push({
        name: 'tenant_termination_order',
        params: {
          id: this.id,
          order_type: 'billing'
        },
        query: { order_type: 'billing' }
      })
    },
    showProcessNotificationModal() {
      this.isShowProcessNotificationModal = true
    },
    showConfirmOrderModal() {
      this.serviceOrderId = this.order.service_order_id
      if (this.orderType === 'service') {
        this.isShowConfirmOrderModal = true
      } else {
        this.isShowConfirmSubscribeModal = true
      }
    },
    showToDressRoomModal() {
      this.isShowToDressRoomModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showCancelServiceOrderModal() {
      this.isShowCancelServiceOrderModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    showConfirmAuditoriumServiceModal() {
      this.isShowConfirmAuditoriumServiceModal = true
    },
    printConfirmAuditoriumService() {
      if (!this.order.is_auditorium_service) {
        this.$info({
          title: '还未进行礼厅交接',
          content: ''
        })
        return
      }
      this.$info({
        title: '正在生成礼厅交接单PDF文件，请耐心等候',
        content: ''
      })
      createAuditoriumServicePdf(this.id).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
    },
    sendWearArmbandNoticeModal() {
      this.isShowSendWearArmbandNoticeModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.funeralHomeId = this.order.funeral_home_id
    },
    wearArmband() {
      wearArmband(this.order.service_order_id, {}).then((res) => {
        if (res.code === 0) {
          this.fetchOrder()
        }
      })
    },
    showModifyApplyModal() {
      validUpdateOrderMaster(Object.assign({ order_id: this.id })).then((res) => {
        if (res.data.result) {
          this.isShowModifyMasterModal = true
        } else {
          this.isShowModifyMasterApplyModal = true
        }
        this.name = res.data.name
        this.phone = res.data.phone
      })
    },

    showUserNoticeModal() {
      this.isShowUserNoticeModal = true
      this.serviceOrderId = this.order.service_order_id
      this.deathName = this.order.death_name
      this.hallName = this.order.hall_name
      this.orderNo = this.order.order_no
      this.orderUserName = this.order.head_name
    },

    showModifyHeadUserModal() {
      validUpdateOrderHeadUser(Object.assign({ order_id: this.id })).then((res) => {
        if (res.data.result) {
          this.isShowModifyHeadUserModal = true
          this.userId = this.order.user_id
        } else {
          this.isShowModifyHeadUserApplyModal = true
          this.name = res.data.name
          this.phone = res.data.phone
        }
      })
    },
    showPrintQrModal() {
      this.isShowPrintQrModal = true
      this.serviceOrderId = this.order.service_order_id
    },
    fetchTabs() {
      if (this.orderType === 'service') {
        this.tabs = this.serviceOrderTabs
      } else {
        this.tabs = this.subscribeOrderTabs
        this.currentComponent = 'SubscribeBasicInformation'
      }
    },
    handleSelectMenu({
      item,
      key,
      selectedKeys
    }) {
      if (key === 'OperationHistory') {
        this.orderId = this.order.record_id
      } else {
        this.orderId = this.id
      }
      this.currentComponent = key
    },
    closeModal() {
      this.$emit('completed')
      this.isShow = false
    },
    updateModal() {
      this.$refs.child.fetchData()
      this.$warning({
        title: '更新成功',
        content: ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
.left-menus {
  height: 110%;
  background: #e8e8e8;
  margin-top: -23px;
}

.right-content {
  height: 500px;
  overflow: auto;
}

.left-menus /deep/ .ant-radio-button-wrapper {
  height: 50px;
  width: 160px;
  padding: 0;
  border: none;
  line-height: 48px;
  display: block;
  text-align: center;
}

.footer-btn {
  display: flex;
  flex-wrap: wrap;

  .ant-btn {
    margin: 5px;
  }
}
</style>
